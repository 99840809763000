import React from 'react';

import styles from './styles.module.scss';

interface Props {
  item: ImageFragment;
}

const ImageBlock = ({ item }: Props) => {
  const url = item.image?.[0]?.url;

  const title = item.image?.[0]?.title || '';

  return url ? (
    <div className={styles.imageBlock}>
      <img src={url} alt={title} />
    </div>
  ) : null;
};

export default ImageBlock;
