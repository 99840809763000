import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

interface Props {
  item: RichTextFragment;
  fullColumn?: boolean;
}

const TextBlock = ({ item, fullColumn }: Props) => (
  <div
    className={cc({ [styles.textBlock]: true, [styles.fullColumn]: fullColumn })}
    dangerouslySetInnerHTML={{ __html: item.body! }}
  />
);

export default TextBlock;
