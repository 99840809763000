import React from 'react';
import cc from 'classcat';

import AccordionBlock from '@/components/AccordionBlock';
import IFrameBlock from '@/components/IFrameBlock';
import ImageBlock from '@/components/ImageBlock';
import TextBlock from '@/components/TextBlock';

import styles from './ContentRenderer.module.scss';

export function renderColumnItem(item: Unboxed<ColumnFragment['children']> | RowFragment) {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'pageContent_richText_BlockType':
      return <TextBlock key={item.uid!} item={item} />;
    case 'pageContent_image_BlockType':
      return <ImageBlock key={item.uid!} item={item} />;
    case 'pageContent_accordion_BlockType':
      return <AccordionBlock key={item.uid!} items={item.accordion as AccordionFragment[]} />;
    case 'pageContent_competition_BlockType':
      return (
        item?.mailchimpCompetitionUrl && (
          <div key={item.uid!} className="col-12">
            <IFrameBlock key={item.uid!} url={item.mailchimpCompetitionUrl} />
          </div>
        )
      );
    default:
      // Should never happen
      console.warn(`failed to render column item with typename ${item['__typename']} - no render case exists`);
      return null;
  }
}

export function renderRowItem(item: Unboxed<RowFragment['children']>, columnClass: string) {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'pageContent_column_BlockType':
      return (
        <div key={item.uid!} className={columnClass}>
          {item.children?.map((child: Unboxed<ColumnFragment['children']> | RowFragment) =>
            child ? renderColumnItem(child) : null,
          )}
        </div>
      );
    case 'pageContent_richText_BlockType':
      return (
        <div key={item.uid!} className="col-12">
          <TextBlock item={item} fullColumn />
        </div>
      );
    case 'pageContent_image_BlockType':
      return (
        <div key={item.uid!} className="col-12">
          <ImageBlock key={item.uid!} item={item} />
        </div>
      );
    case 'pageContent_competition_BlockType':
      return (
        item?.mailchimpCompetitionUrl && (
          <div key={item.uid!} className="col-12">
            <IFrameBlock key={item.uid!} url={item.mailchimpCompetitionUrl} />
          </div>
        )
      );
    case 'pageContent_accordion_BlockType':
      return (
        <div className="col-12">
          <AccordionBlock key={item.uid!} items={item.accordion as AccordionFragment[]} />
        </div>
      );
    default:
      // Should never happen
      console.warn(`failed to render row item with typename ${item['__typename']} - no render case exists`);
      return null;
  }
}

function renderRow(row: RowFragment) {
  const children = row.children;
  if (!children) {
    return null;
  }

  const classNames = [styles.row];
  if ('align' in row && row.align) {
    classNames.push(styles[row.align]);
  }

  const background = {
    backgroundColor: 'transparent',
    backgroundImage: 'none',
  };

  const columnClassNames: string[] = [];
  if ('maxColumns' in row && row.maxColumns && Number(row.maxColumns)) {
    columnClassNames.push(`col-lg-${12 / Number(row.maxColumns)}`);
    switch (Number(row.maxColumns)) {
      case 3:
        columnClassNames.push('pb-1');
        break;
      case 4:
        columnClassNames.push('col-md-6 mb-2 mb-lg-0');
        break;
      default:
        break;
    }
  } else {
    columnClassNames.push('col-12');
  }

  return (
    <div key={row.uid!} className={cc(classNames)} style={background}>
      <div className="container">
        <div className="row rowRoot">{children.map((child) => renderRowItem(child, cc(columnClassNames)))}</div>
      </div>
    </div>
  );
}

export function renderPageContent(item: Craft.PageContent) {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'pageContent_row_BlockType':
      return renderRow(item as any as RowFragment);
    default:
      console.warn(`failed to render element`);
      return null;
  }
}
