import React from 'react';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  width?: number | string;
  height?: number | string;
  url: string;
}

const IFrameBlock = ({ title = 'iFrame', width = '100%', height = '916px', url }: Props) => {
  return <iframe className={styles.iframe} title={title} height={height} width={width} src={url} />;
};

export default IFrameBlock;
